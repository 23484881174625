import React, { Component } from 'react'
import Link from 'gatsby-link'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Header.css'
import '../css/index.css'
import { Form } from 'react-bootstrap'
import Fade from 'react-reveal/Fade';
import Switch from '../images/switch.svg';
import Switch2 from '../images/switch3.svg';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dark: this.isDarkMode(),
      data: [],
    }
  }
  
  switchTheme = () => {
    if (this.isDarkMode()) {
      this.setState({
        dark: false
      })
      document.documentElement.style.setProperty('--color-solid', '#2b2b2b');
      document.documentElement.style.setProperty('--color-surface', 'white');
      document.documentElement.style.setProperty('--color-mode', 'light');
      document.documentElement.style.setProperty('--color-secondary', 'rgba(154, 154, 154, .8)');
      document.documentElement.style.setProperty('--switch-background', '#DCDCDC');
      document.documentElement.style.setProperty('--color-link', '#446DF6');
    } else {
      this.setState({
        dark: true,
      })
      document.documentElement.style.setProperty('--color-solid', 'hsl(210, 50%, 96%)');
      document.documentElement.style.setProperty('--color-surface', '#161618');
      document.documentElement.style.setProperty('--color-primary', 'rgb(34, 38, 57)');
      document.documentElement.style.setProperty('--color-secondary', 'white');
      document.documentElement.style.setProperty('--border-bottom','rgba(163, 163, 163, 0.5)');
      document.documentElement.style.setProperty('--color-mode', 'dark');
      document.documentElement.style.setProperty('--switch-background', '#3A3A3A');
      document.documentElement.style.setProperty('--color-link', '#FFC914');
      document.documentElement.style.setProperty('--color-link', '#f27059');

      // document.documentElement.style.setProperty('--color-link', '#f72585');
      
    }
  }
  
  isDarkMode = () => {
    if (typeof window !== 'undefined') {
      return window.getComputedStyle(document.body).getPropertyValue('--color-mode') === 'dark';
    } else {
      return false;
    }
  }

  render() {
    const {dark} = this.state;
    console.log("Dark:", dark);

    return (
      <div className="Header">
        {/* <Fade> */}
        <div className="HeaderGroup">
          <div><Link to="/">Home</Link></div>
          <div className="RightLinks">
            <Link to="/about">About</Link>
            <div className="DarkModeSelector">
              <a className = "toggle" onClick = {this.switchTheme}>
                {dark ? 
                  <img src={Switch2} /> : 
                  <img src={Switch} />
                }
              </a>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default Header;
